/**
 * Enviornment variable for Test System
 */
export const environment = {
  production: false,
  test: true,
  local: false,
  STAGE: 'test',
  graphicsId: '',
  backend_protocol: 'https://',
  backend_prefix: 'graphics-server',
  client_prefix: 'graphics-client',
  backend_url: 'https://as-global-appgfxserver-we-test.azurewebsites.net/',
  start_path: 'startParameter',
  finish_path: 'order/finish',
  convert_path: 'upload',
  long_poll_convert_path: 'upload/convert-image-lp',
  long_poll_finish_path: 'order/finish-lp',
  convert_sortimo_image_path: 'upload/sortimo-image',
  mirror_path: 'mirror',
  all_image_path: 'images',
  logLevel: 'debug',
  assets_url: 'https://gfxassets.sortimo-test.de/',
};
